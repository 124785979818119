import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AlertController, LoadingController, ModalController, ToastController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  public loading;

  constructor(private alertController: AlertController,
              private modalController: ModalController,
              private toastController: ToastController,
              private loadingController: LoadingController,
              private router: Router,) {
  }

  /**
   *
   * @param header texto header
   * @param message texto message
   * @param buttons    * Buttons es un arreglo de objects
   * más info: https://ionicframework.com/docs/api/alert#usage
   */
  async showAlert(header, message, buttons?: any[]) {
    const alert = await this.alertController.create({
      mode: 'ios',
      header,
      message,
    });
    if (buttons) {
      alert.buttons = [...buttons];
    }

    await alert.present();
  }

  async showModal(modalPage, cssClass: string[], componentProps?) {
    const css = cssClass.length === 0 ? ['normal-modal'] : cssClass;
    const modal = await this.modalController.create({
      component: modalPage,
      componentProps,
      cssClass: css
    });
    await modal.present();
    return modal;
  }

  dismissModal(data?: any) {
    this.modalController.dismiss(data);
  }

  async showLoading(message?, duration?) {
    this.loading = await this.loadingController.create({
      message: message || 'Loading...',
      spinner: 'bubbles',
      // eslint-disable-next-line no-bitwise
      duration: duration | 1000,
      cssClass: 'fan-loading'
    });
    await this.loading.present();
  }

  async dismissLoading() {
    await this.loading.dismiss();
  }

  /**
   *
   * @param pText texto a mostrar en el toast
   * @param pColor color del toast
   * @param duration duración del toast
   */
  async showToast(pText, pColor = 'dark', duration = 2000) {
    const toast = await this.toastController.create({
      position: 'bottom',
      message: pText,
      color: pColor,
      duration,
    });
    toast.present();
  }

  goPage(page, queryParams?: any) {
    this.router.navigate([`${ page }`], queryParams ? queryParams : {});
  }

  sortObjectArray(array: any[], descentdent: boolean, sortKey: string, childSortKey?: string) {
    const order = descentdent ? -1 : 1;
    if (childSortKey) {
      return array.sort((a, b) => (a[sortKey][childSortKey] > b[sortKey][childSortKey]) ?
        order : ((b[sortKey][childSortKey] > a[sortKey][childSortKey]) ? -order : 0));
    }
    return array.sort((a, b) => (a[sortKey] > b[sortKey]) ?
      order : ((b[sortKey] > a[sortKey]) ? -order : 0));
  }

  randomHash(length: number, idToBind?: number, idAtEnd?: boolean) {
    const id = idToBind ? idToBind.toString() : undefined;
    const fixedLength = length + 2;
    let hash = Math.random().toString(fixedLength).substring(2, (fixedLength));
    hash = id ?
      idAtEnd ?
        (hash + id).substring(0, fixedLength - id.length) :
        (id + hash).substring(0, fixedLength - id.length)
      : hash;
    return hash;
  }

  urlToBase64(url) {
    return fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      }));
  }

  async presentAlert(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const confirm = await this.alertController.create({
        header: 'Warning',
        message: 'You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => resolve(false),
          },
          {
            text: 'OK',
            handler: () => resolve(true),
          },
        ],
      });
      await confirm.present();
    });
  }

  get(obj: any, key: string) {
    const keys = key.split('.');
    for (const i of keys) {
      obj = obj[i];
      if (obj === undefined) {
        break;
      }
    }
    return obj;
  }
}
