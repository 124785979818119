import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'isValueImage'
})
export class IsValueImagePipe implements PipeTransform {

  transform(value: string): unknown {
    return value ? value.indexOf('data:image') >= 0 : false;
  }

}
