import {Component, Input, OnInit} from '@angular/core';
import {Pack} from '../../interface/pack';

@Component({
  selector: 'app-mini-pack',
  templateUrl: './mini-pack.component.html',
  styleUrls: ['./mini-pack.component.scss'],
})
export class MiniPackComponent implements OnInit {
  @Input() pack: Pack;

  constructor() {
  }

  ngOnInit() {
  }

}
