import {Component, Input, OnInit} from '@angular/core';
import {SearchParam} from '../../interface/searchParam';
import {ModalController} from '@ionic/angular';


@Component({
  selector: 'app-searchbar',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss'],
})
export class SearchModalComponent implements OnInit {

  @Input() searchParams: SearchParam[];
  whereParams: object;
  searchText: string;

  constructor(
    private modalCtrl: ModalController
  ) {
  }

  ngOnInit() {
    const where = {};
    for (const param of this.searchParams) {
      where[param.name] = param.placeholder || '';
    }
    this.whereParams = where;
  }

  search() {
    const filtered = Object.keys(this.whereParams)
      .filter((k) => this.whereParams[k] != null && this.whereParams[k] !== '')
      .reduce((a, k) => ({ ...a, [k]: this.whereParams[k] }), {});
    this.modalCtrl.dismiss({
      refresh: true,
      search: this.searchText,
      where: filtered,
    }).then();
  }

  dismiss() {
    this.modalCtrl.dismiss({ refresh: false }).then();
  }
}
