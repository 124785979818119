import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutWrapperComponent} from './component/layout-wrapper/layout-wrapper.component';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {IsValueImagePipe} from './pipes/is-value-image.pipe';
import {SearchModalComponent} from './component/search-modal/search-modal.component';
import {PackItemComponent} from './component/pack-item/pack-item.component';
import {UserInfoComponent} from './component/user-info/user-info.component';
import {UploadFileModalComponent} from './component/upload-file-modal/upload-file-modal.component';
import {FormsModule} from '@angular/forms';
import {NgxFileDropModule} from 'ngx-file-drop';
import {LayoutModalComponent} from './component/layout-modal/layout-modal.component';
import {ChatComponent} from './component/chat/chat.component';
import {TranslocoModule} from '@ngneat/transloco';
import {MiniPackComponent} from './component/mini-pack/mini-pack.component';
import { BaseUtilsComponent } from './component/base-utils/base-utils.component';


@NgModule({
  declarations: [
    LayoutWrapperComponent,
    PackItemComponent,
    SearchModalComponent,
    UploadFileModalComponent,
    UserInfoComponent,
    IsValueImagePipe,
    LayoutModalComponent,
    ChatComponent,
    MiniPackComponent,
    BaseUtilsComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    NgxFileDropModule,
    TranslocoModule
  ],
  exports: [
    LayoutWrapperComponent,
    IsValueImagePipe,
    SearchModalComponent,
    PackItemComponent,
    UserInfoComponent,
    UploadFileModalComponent,
    LayoutModalComponent,
    ChatComponent,
    TranslocoModule,
    MiniPackComponent,
    BaseUtilsComponent
  ]
})
export class SharedModule {
}
