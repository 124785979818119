import {Component, OnInit} from '@angular/core';
import { LanguageService } from './services/language.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    {title: 'Opt 1', url: '/folder/Inbox', icon: 'mail'},
    {title: 'Opt 2', url: '/folder/Outbox', icon: 'paper-plane'},
    {title: 'Opt 3', url: '/folder/Favorites', icon: 'heart'},
    {title: 'Opt 4', url: '/folder/Archived', icon: 'archive'},
    {title: 'Opt 5', url: '/folder/Trash', icon: 'trash'},
    {title: 'Opt 6', url: '/folder/Spam', icon: 'warning'},
  ];

  constructor(private readonly languageService: LanguageService) {
  }

  get environment() {
    return environment.env;
  }

  ngOnInit(): void {
    this.languageService.onInit();
  }
}
