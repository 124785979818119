import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {AppConstants} from '../../app.constants';
import {AuthService} from '../../services/auth.service';
import {LoggedInfo} from '../interface/auth';
import {catchError, filter, switchMap, take} from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers: any = {};
    headers[AppConstants.keys.lang] = localStorage.getItem(AppConstants.keys.lang) ? localStorage.getItem(AppConstants.keys.lang) : 'en';

    if (this.authService.checkIfLogged() && !request.url.includes('login')) {
      const loggedInfo: LoggedInfo = this.authService.getLoggedInfo();
      headers[AppConstants.headers.authorization] = loggedInfo.idToken.token ? loggedInfo.idToken.token : '';
      headers[AppConstants.headers.accessToken] = loggedInfo.accessToken.token ? loggedInfo.accessToken.token : '';
    }
    request = request.clone({setHeaders: headers});
    return next.handle(request).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !request.url.includes('login') &&
          (error.status === 401 || error.status === 0)) {
          return this.handleUnauthorize(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  handleUnauthorize(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refreshToken().pipe(
        switchMap((response: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(response);
          return next.handle(this.addTokenHeader(request));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(response => next.handle(this.addTokenHeader(request)))
      );
    }
  }

  addTokenHeader(request: HttpRequest<any>) {
    const loggedInfo: LoggedInfo = this.authService.getLoggedInfo();
    return request.clone({
      headers: request.headers
        .set(AppConstants.headers.authorization, loggedInfo.idToken.token)
        .set(AppConstants.headers.accessToken, loggedInfo.accessToken.token)
    });
  }
}
