<ion-grid class="pack">
  <ion-row>
    <ion-col class="pack-image">
      <ion-thumbnail class="mt-20">
        <img [src]="pack.thumbnail?.url" alt="">
      </ion-thumbnail>
    </ion-col>

    <ion-col>
      <ion-row>
        <ion-text>
          <h6 class="bold">{{pack.name}}</h6>
          <p>{{pack.shortDescription.slice(0, 60) + '...'}}</p>
        </ion-text>
      </ion-row>

      <ion-row>
        <ion-text class="details">
            <span class="bold">Release date:</span>
            <span class="ml-5">{{pack.release_date | date:'M/d/yyyy'}}</span>
          </ion-text>
          <ion-text class="details">
            <span class="bold">Price:</span>
            <span class="ml-5">{{defaultCurrency | transloco}} {{pack.price}}</span>
          </ion-text>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>
