/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { User } from '../../interface/user';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  loggedUser: User;

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    this.loggedUser = this.authService.getloggedUser();
  }


}
