<section class="upload-modal-wrapper">
  <article>
    <section class="upload-modal-container">
      <article class="header">
        <ion-icon size="large" src="assets/icon/upload.svg"></ion-icon>
        <ion-item *ngIf="data.file" class="mt-20">
          <ion-icon class="mr-10" name="document-attach-outline"></ion-icon>
          <ion-label>{{data.file.objectKey}}</ion-label>
        </ion-item>
      </article>
      <article class="content">
        <ng-container *ngIf="data.file === null">
          <ngx-file-drop dropZoneClassName="upload-dropzone-section"
                         *ngIf="base64File === undefined"
                         (onFileDrop)="dropped($event)">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              <div (click)="openFileSelector()" class="ion-justify-content-center w-full ion-padding pointer">
                <ion-text class="title mb-10" style="display: flex; justify-content: center;">
                  {{data.title}}
                </ion-text>
                <ion-text class="secondary" style="display: flex; justify-content: center;">
                  {{data.subtitle}}
                </ion-text>
                <ion-text class="secondary" style="display: flex; justify-content: center;">
                  Only&nbsp;
                  <span *ngFor="let item of data.allowedFiles; let i = index">
                    *.{{item}}<span *ngIf="i < data.allowedFiles.length-1">,</span>
                  </span>
                  &nbsp;files are allowed.
                </ion-text>
              </div>
            </ng-template>
          </ngx-file-drop>
        </ng-container>
        <!--Preview zone: only if its an image-->
        <div class="art-container">
          <img *ngIf="data.file" [src]="data.file?.url" alt="">
        </div>
      </article>
    </section>
  </article>
  <article>
    <ion-row>
      <ion-col class="text-center">
        <ion-button fill="outline"
                    expand="block"
                    class="button-border bold"
                    color="warning"
                    (click)="popFileChooser()">
          <input type="file" #uploader hidden>
          <span>Search file</span></ion-button>
      </ion-col>
      <ion-col class="text-center">
        <ion-button class="bold"
                    color="warning"
                    expand="block"
                    (click)="closeModal(true)">Upload template</ion-button>
      </ion-col>
    </ion-row>
  </article>
</section>



