<ion-split-pane contentId="main-content" [when]="getMinWidth">
  <ion-menu contentId="main-content" type="overlay">
    <ion-content>
      <ion-list id="inbox-list">
        <ion-list-header>
          <h4>Dillom.RIP</h4>
        </ion-list-header>
        <ion-note></ion-note>
        <ion-menu-toggle auto-hide="false">
          <ion-item *ngFor="let p of appPages; let i = index" routerDirection="root" [routerLink]="[p.url]" lines="none"
            detail="false" routerLinkActive="selected">
            <em class="material-symbols-outlined">{{p.icon}}</em>
            <ion-label>{{ p.title }}</ion-label>
          </ion-item>
          <!-- <ion-item lines="none" color="transparent" *transloco="let t; read:'language'">
            <ion-label class="language-label menu-list-select">
              <em class="material-symbols-outlined">
                language
              </em>
              Language:
            </ion-label>
            <ion-select interface="popover" [value]="languageService.currentLanguage()"
              (ionChange)="setLanguage($event)">
              <ion-select-option value="en">{{t('en')}}</ion-select-option>
              <ion-select-option value="es">{{t('es')}}</ion-select-option>
            </ion-select>
          </ion-item> -->
        </ion-menu-toggle>
        <ion-item lines="none" class="ion-margin-top">
          <ion-label (click)="logout()" class="pointer logout">Log out</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
  <ion-content id="main-content">
    <div id="hamburger-icon" (click)="open()">
      <span class="line line-1"></span>
      <span class="line line-2"></span>
      <span class="line line-3"></span>
    </div>
    <!--aqui puede ir un header-->
    <ng-content></ng-content>
  </ion-content>
</ion-split-pane>