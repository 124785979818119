import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { LanguageService } from 'src/app/services/language.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-layout-wrapper',
  templateUrl: './layout-wrapper.component.html',
  styleUrls: ['./layout-wrapper.component.scss'],
})
export class LayoutWrapperComponent {
  public appPages = [
    { title: 'Dashboard', url: '/panel/dashboard', icon: 'grid_view' },
    { title: 'Analytics', url: '/panel/analytics', icon: 'bar_chart' },
    { title: 'Products', url: '/panel/products', icon: 'create_new_folder' },
    { title: 'Packs', url: '/panel/packs', icon: 'inventory_2' },
    { title: 'Fans', url: '/panel/fans', icon: 'mood' },
    // { title: 'Cash Withdrawal', url: '/panel/withdrawal', icon: 'credit_card' },
    // { title: 'Account', url: '/panel/account', icon: 'person' },
    // { title: 'Help', url: '/panel/help', icon: 'support_agent' },
  ];
  public readonly getMinWidth = '(min-width: 1525px)';

  constructor(public readonly authService: AuthService, private router: Router, private menu: MenuController,
    public readonly languageService: LanguageService) {
  }

  async open() {
    await this.menu.open();
  }

  public logout() {
    this.authService.logout();
    this.router.navigate(['/user']);
  }

  public setLanguage(e) {
    this.languageService.setLanguage(e.detail.value);
  }
}
