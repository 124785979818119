export class AppConstants {
  public static states: any = {
    pendingPack: 'pending of verification',
    approvedPack: 'verified and published',
  };

  public static keys: any = {
    user: 'user',
    token: 'idToken',
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',
    lang: 'lang',
  };

  public static headers: any = {
    authorization: 'Authorization',
    accessToken: 'Access-Token',
  };

  public static languages: any = {
    english: 'en',
    spanish: 'es'
  };

  public static misc: any = {
    file: {
      urlCheck: 'http',
      urlSafeCheck: 'https'
    }
  };

  public static formats: any = {
    date: 'YYYY-MM-DD',
    date2: 'DD-MM-yyyy',
    date3: 'DD-MM-yyyy h:mma',
    date4: 'YYYY-MM-DDTHH:mm',
    date5: 'MM/DD/YYYY'
  };

  public static modal: any = {
    sizes: {
      small: ['small-modal'],
      normal: ['normal-modal', 'full-screen-mobile-modal'],
      wide: ['wide-modal'],
      uploadFile: ['upload-modal'],
      detailed: ['detailed-modal'],
      customAuctionProgress: ['custom-auction-progress-modal'],
      selectProducts: ['select-products-modal', 'full-screen-mobile-modal'],
    }
  };

  public static files: any = {
    video: {
      title: 'Drop the video file.',
      subtitle: 'Please only import image type files.  Max 2000 MB',
      allowedFiles: ['jpg', 'tiff', 'gif', 'png']
    },
    metadata: {
      title: 'Drop the template here with the metadata.',
      subtitle: 'If you don\'t have the template please download right here.',
      allowedFiles: ['xlx', 'xlsx', 'txt']
    },
    thumbnail: {
      title: 'Drop the thumbnail image file.',
      subtitle: 'Please only import image type files.  Max 2000 MB',
      allowedFiles: ['jpg', 'tiff', 'gif', 'png']
    }
  };
}
