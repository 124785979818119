<ion-grid class="chat-box">
  <ion-row>
    <ion-text class="bold">QUICK CHAT</ion-text>
  </ion-row>

  <ion-row *ngFor="let message of messages">
    <ion-avatar class="avatar mr-5" *ngIf="user.name===message.name">
      <img src="assets/images/user/user-chat1.svg">
    </ion-avatar>

    <ion-row 
      style="width: 85%;"
      class="ion-margin-vertical" 
      [ngClass]="{'chat-message-sent': user.name===message.name, 'chat-message-received': user.name!==message.name}" 
    >  
      <ion-text>{{message.text}}</ion-text>
    </ion-row>

    <ion-avatar class="avatar ml-5" *ngIf="user.name!==message.name">
      <img src="assets/images/user/user-chat2.svg">
    </ion-avatar>
  </ion-row>

  <ion-row class="ion-align-items-center">
    <ion-input
      class="input-text mt-10 mr-5"
      placeholder="Your message...">
    </ion-input>
    <span class="send-icon">
      <ion-icon size="large" name="arrow-up-outline"></ion-icon>
    </span>
  </ion-row>
</ion-grid>