<ion-header translucent>
  <ion-toolbar>
    <ion-title>Filters</ion-title>
  </ion-toolbar>
</ion-header>
<ion-grid>
  <ion-row>
    <ion-col>
      <ion-searchbar [(ngModel)]="searchText"></ion-searchbar>
    </ion-col>
  </ion-row>
  <ion-item *ngFor="let param of searchParams">
    <ion-label>{{param.label}}</ion-label>
    <ion-input *ngIf="param.type === 'text'" [(ngModel)]="whereParams[param.name]"></ion-input>
    <ion-input *ngIf="param.type === 'number'" type="number" [(ngModel)]="whereParams['{{param.name}}']"></ion-input>
    <ion-select *ngIf="param.type === 'select'" interface="popover">
      <ion-select-option *ngFor="let option of param.options" value="{{option.value}}">
        {{option.label}}
      </ion-select-option>
    </ion-select>
    <ion-input *ngIf="param.type === 'date'" type="date" [(ngModel)]="whereParams[param.name]"></ion-input>
  </ion-item>
  <ion-row align-center>
    <ion-col>
      <ion-button color="warning" (click)="search()" expand="full">Apply</ion-button>
    </ion-col>
    <ion-col>
      <ion-button color="warning" (click)="dismiss()" expand="full">Cancel</ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
