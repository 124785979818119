import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AppConstants } from '../app.constants';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private readonly translocoService: TranslocoService) {}

  public currentLanguage = () => this.translocoService.getActiveLang();

  public setLanguage = (lang: string) => {
    localStorage.setItem(AppConstants.keys.lang, lang);
    this.translocoService.setActiveLang(lang);
  };

  public onInit() {
    const hasLang = localStorage.getItem(AppConstants.keys.lang);

    if (hasLang) {
      this.setLanguage(hasLang);
      return;
    }

    const browserLanguage = navigator.language?.split('-').shift();
    const getAvailableLangs =
      this.translocoService.getAvailableLangs() as string[];

    if (getAvailableLangs.includes(browserLanguage)) {
      this.setLanguage(browserLanguage);
      return;
    }
  }
}
