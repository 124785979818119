import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgxFileDropEntry} from 'ngx-file-drop';
import {UtilsService} from 'src/app/services/utils.service';
import {DataUploadFileModal} from '../../interface/common';
import {AppConstants} from '../../../app.constants';

@Component({
  selector: 'app-upload-file-modal',
  templateUrl: './upload-file-modal.component.html',
  styleUrls: ['./upload-file-modal.component.scss'],
})
export class UploadFileModalComponent implements OnInit {

  @ViewChild('uploader', {static: true}) uploader: ElementRef<HTMLInputElement>;
  @Input() data: DataUploadFileModal;

  files: NgxFileDropEntry[] = [];
  file: File;
  base64File: string;
  original: any;

  constructor(private utilService: UtilsService) {
  }

  ngOnInit() {
    this.original = this.data.file;
    console.log('@==>', this.data);
  }

  async popFileChooser() {
    this.uploader.nativeElement.click();
    this.uploader.nativeElement.onchange = async (ev) => {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const file = ev.target['files'][0];
      await this.pickupFile(file);
    };
  }

  async dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file(async (file: File) => {
          // Here you can access the real file
          await this.pickupFile(file);
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        await this.utilService.showToast('Only files are allowed');
      }
    }
  }

  async pickupFile(file: any) {
    const ext = file.name.split('.').pop().toLowerCase();
    if (!this.data.allowedFiles.includes(ext)) {
      await this.utilService.showToast('File extension not allowed');
      return;
    }
    this.file = file;
    this.data.file = {
      objectKey: file.name,
      url: await this.file2Base64(file)
    };
  }

  file2Base64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString() || '');
      reader.onerror = error => reject(error);
    });
  }

  async closeModal(withData: boolean) {
    if (this.original) {
      // esta editando
      if (withData && this.data.file.url.indexOf(AppConstants.misc.file.urlCheck) === -1) {
        this.utilService.dismissModal({
          fileBase64: this.data.file.url,
          fileName: this.data.file.objectKey
        });
      } else {
        this.utilService.dismissModal(null);
      }
    } else {
      this.utilService.dismissModal({
        fileBase64: this.data.file.url,
        fileName: this.data.file.objectKey
      });
    }
  }
}
