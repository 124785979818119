import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {environment} from '../../environments/environment';
import {LoginResponse, User, UserToken} from '../shared/interface/user';
import {Observable, of} from 'rxjs';
import {BaseResponse} from '../shared/interface/response';
import {AppConstants} from '../app.constants';
import {catchError, tap} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService<User> {
  constructor(private httpClient: HttpClient,
              private router: Router) {
    super(httpClient);
    this.url = `${environment.apiServer}`;
  }

  public getloggedUser(): User {
    const user = JSON.parse(localStorage.getItem('user'));
    return user;
  }

  checkIfLogged() {
    return localStorage.getItem(AppConstants.keys.token);
  }

  loginDashboard(email: string, password: string): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(`${this.url}/dashboard/login`, {
      email,
      password,
    }).pipe(
      tap(response => this.storeUserAndTokens(response.data.data))
    );
  }

  refreshToken(): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(`${this.url}/users/refresh-token`, {
      refreshToken: this.getLoggedInfo().refreshToken.token
    }).pipe(
      tap((response: any) => {
        response.data.refreshToken = this.getLoggedInfo().refreshToken;
        this.storeTokens(response.data);
      }),
      catchError(error => {
        this.router.navigate(['/user']);
        return of(null);
      })
    );
  }

  getLoggedInfo(): UserToken {
    return {
      idToken: JSON.parse(localStorage.getItem(AppConstants.keys.token)),
      accessToken: JSON.parse(localStorage.getItem(AppConstants.keys.accessToken)),
      refreshToken: JSON.parse(localStorage.getItem(AppConstants.keys.refreshToken)),
    };
  }

  storeTokens(tokens) {
    localStorage.setItem(AppConstants.keys.token, JSON.stringify(tokens.idToken));
    localStorage.setItem(AppConstants.keys.accessToken, JSON.stringify(tokens.accessToken));
    localStorage.setItem(AppConstants.keys.refreshToken, JSON.stringify(tokens.refreshToken));
  }

  storeUserAndTokens(response) {
    localStorage.setItem(AppConstants.keys.user, JSON.stringify(response));
    this.storeTokens(response.tokens);
  }

  validateUserIsLogged(): boolean {
    return !!localStorage.getItem('token');
  }

  forgotPassword(email: string): Observable<any> {
    return this.httpClient.post<BaseResponse>(
      `${this.url}/users/forgot-password`,
      {email}
    );
  }

  resetPassword(data: any): Observable<any> {
    return this.httpClient.post<BaseResponse>(
      `${this.url}/users/reset-password`,
      data
    );
  }

  public readonly logout = () => this.resetStorage();

  private resetStorage() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('accessToken');
  }
}
