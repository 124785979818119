import { Component, Input, OnInit } from '@angular/core';
import { Pack } from '../../interface/pack';
import { BaseUtilsComponent } from '../base-utils/base-utils.component';

@Component({
  selector: 'app-pack-item',
  templateUrl: './pack-item.component.html',
  styleUrls: ['./pack-item.component.scss'],
})
export class PackItemComponent extends BaseUtilsComponent implements OnInit {
  @Input() pack: Pack;

  constructor() {
    super();
  }

  ngOnInit() {}

}
