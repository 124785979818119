import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-layout-modal',
  templateUrl: './layout-modal.component.html',
  styleUrls: ['./layout-modal.component.scss'],
})
export class LayoutModalComponent implements OnInit {
  @Input() title: string;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.title = null;
  }

  ngOnInit() {
  }

}
