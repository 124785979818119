import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {

  user = { name: 'Juan' };

  messages = [
    {
      time: new Date('29/10/2022'),
      name: 'Juan',
      text: 'Hola buen dia'
    },
    {
      time: new Date('29/10/2022'),
      name: 'Pedro',
      text: 'Hola que tal'
    },
    {
      time: new Date('29/10/2022'),
      name: 'Juan',
      text: 'Necesito ayuda'
    },
  ];

  constructor() { }

  ngOnInit() {}

}
