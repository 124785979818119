import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-base-utils',
  templateUrl: './base-utils.component.html',
  styleUrls: ['./base-utils.component.scss'],
})
export class BaseUtilsComponent implements OnInit {
  defaultCurrency: string = environment.defaultCurrency;

  constructor() { }

  ngOnInit() {}

}
